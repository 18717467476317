<template>

    <div class="container-fluid footer-background">
        <div class="container footer">
            <div class="footer-content row">
                <div class="col-lg-2 col-md-3  col-12">
                    <router-link to="/" class="pb-4">
                        <img src="/images/logo/footer/footer_logo_gorod.png" alt="" class="footer-gorod">
                    </router-link>
                    <div class="col-mail fix-mail pb-4">
                        <div class="mail-img">
                            <img src="/images/icon/footer/footer_icon_mail.png" alt="" class="mail">
                        </div>
                        <div class="mail-text">
                            <span>info@mrexpo.ru</span>
                        </div>
                    </div>
                </div>
                <div class="footer-content-menu col-lg-8 col-md-5  col-12">
                    <div class="row">
                        <div class="col-6 col-lg-4 menu-el">
                            <a :href="links[$i18n.locale].programm" class="footer-left--text footer-info js-footer-info">{{$t('navbar.about_show')}}</a>
                        </div>
                        <div class="col-6 col-lg-4 menu-el">
                            <a :href="links[$i18n.locale].stream" class="footer-left--text footer-video js-footer-video">{{$t('navbar.ar')}}</a>
                        </div>
                        <div class="col-6 col-lg-4 menu-el">
                            <a :href="links[$i18n.locale].vote" class="footer-left--text footer-video js-footer-video">{{$t('navbar.vote')}}</a>
                        </div>
                        <div class="col-6 col-lg-4 menu-el">
                            <a :href="links[$i18n.locale].speakers"  class="footer-left--text footer-media js-footer-media">{{$t('navbar.media_center')}}</a>
                        </div>
                        <div class="col-6 col-lg-4 menu-el">
                            <a :href="links[$i18n.locale].prize" class="footer-left--text footer-video js-footer-video">{{$t('navbar.prizes')}}</a>
                        </div>
                        <div class="col-6 col-lg-4 menu-el">
                            <a :href="links[$i18n.locale].faq" class="footer-left--text footer-video js-footer-video">FAQ</a>
                        </div>
                    </div>
                </div>
                <div class="footer-content--right col-lg-2 col-md-4  col-12">
                    <div class="content-right--icon">
                        <a href="https://vk.com/goroddetali" target="_blank" class="footer-icon footer-icon--vk js-footer-icon--vk">
                            <img src="/images/icon/footer/footer_icon_vk.png" alt="">
                        </a>
                        <a href="https://instagram.com/gorod_detali" target="_blank" class="footer-icon footer-icon--inst js-footer-icon--inst">
                            <img src="/images/icon/footer/footer_icon_inst.png" alt="">
                        </a>
                        <a href="https://www.facebook.com/goroddetali/" target="_blank" class="footer-icon footer-icon--fb js-footer-icon--fb">
                            <img src="/images/icon/footer/footer_icon_facebook.png" alt="">
                        </a>
                    </div>
                    <div class="content-right--name">
                        <span>Copyright © 2021. mrexpo.ru</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store";

export default {
  name: "ExpoFooter",
    data() {
        return {
            links:{
                ru:{
                    programm: "https://mrexpo.ru/program/",
                    faq: "https://mrexpo.ru/faq/",
                    speakers: "https://mrexpo.ru/speakers/",
                    stream: "https://mrexpo.ru/archive/",
                    prize: "https://mrexpo.ru/prize/",
                    mclasses: "https://mrexpo.ru/master-classes/urij-postrigaj/",
                    vote:"https://mrexpo.ru/vote/"
                },
                en:{
                    faq: "https://mrexpo.ru/en/faq/",
                    programm: "https://mrexpo.ru/en/program/",
                    speakers: "https://mrexpo.ru/en/speakers/",
                    stream: "https://mrexpo.ru/en/archive/",
                    prize: "https://mrexpo.ru/en/prize/",
                    mclasses: "https://mrexpo.ru/en/master-classes/urij-postrigaj/",
                    vote:"https://mrexpo.ru/en/vote/"
                }
            },
        }
    },
  components: {
  },
  watch: {
  },
  computed: {
    userSigned() {
      return store.state.user && !store.state.user.isAnonymous;
    }
  },
}
</script>
<style scoped>
.footer-left--text:hover{
    color: #fff;
}
.fix-mail{
    margin-top: 16px;
}
.footer-content-menu .menu-el {
    padding-left: 50px;
}
@media (max-width: 1399px) {
    .fix-mail{
        margin-top: 4px;
    }
    .footer-content-menu .menu-el{
        padding-top: 6px;
    }
}
@media (max-width: 991px) {
    .footer-content-menu .menu-el {
        padding-left: 10px;
    }
}
</style>
