<template>
  <div>
    <!-- <ExpoHeader></ExpoHeader> -->
    <div class="centered-container">
      <div class="login">
        <div class="title">
          <span>{{$t('login.h1')}}</span>
        </div>
        <div class="actions">
          <button class="buttonLogin facebookLogin" @click="facebookLogin()">
            <div class="login-img login-img--fb"></div>
            <span>Facebook</span>
          </button>
          <button class="buttonLogin vkLogin" @click="vkLogin()">
            <div class="login-img login-img--vk"></div>
            <span>{{$t('login.vk')}}</span>
          </button>
          <button class="buttonLogin yandexLogin" @click="yandexLogin()">
            <div class="login-img login-img--ya"></div>
            <span>{{$t('login.ya')}}</span>
          </button>
          <button class="buttonLogin googleLogin" @click="googleLogin()">
            <div class="login-img login-img--goo"></div>
            <span>Google</span>
          </button>
          <button class="buttonLogin okLogin" @click="okLogin()">
            <div class="login-img login-img--od"></div>
            <span>{{$t('login.ok')}}</span>
          </button>
          <router-link to="/expo" class="buttonLogin without-reg">
            <span>{{$t('login.without-reg')}}</span>
          </router-link>
        </div>

        <loading :active.sync="loading"
                :can-cancel="false"
                :is-full-page="false">
        </loading>

      </div>
      <div class="background"/>
    </div>
    <ExpoFooter></ExpoFooter>
  </div>
</template>




<script>
import firebase from "firebase/app"
import 'firebase/auth'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import ExpoHeader from "@/components/ExpoHeader";
import ExpoFooter from "@/components/ExpoFooter";

export default {
  name: 'login',
  data() {
    return {
      loading: false,
    }
  },
  components: {
    Loading,
    // ExpoHeader,
    ExpoFooter,
  },
  methods: {
    googleLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('email');
      this.onLoginBegin()
      firebase.auth().signInWithPopup(provider)
          .then(this.onLoginSuccessful)
          .catch(this.onLoginFail)
          .finally(this.onLoginEnd);
    },
    facebookLogin() {
      const provider = new firebase.auth.FacebookAuthProvider();
      provider.addScope('email');
      this.onLoginBegin()
      firebase.auth().signInWithPopup(provider)
          .then(this.onLoginSuccessful)
          .catch(this.onLoginFail)
          .finally(this.onLoginEnd);
    },
    phoneLogin() {
      this.$router.push({name: 'PhoneLogin'});
    },
    _oauthPopUpLogin(path, winName, width, height) {
      this.onLoginBegin();
      const win = this._popupCenter({url: path, title: winName, w: width, h: height});
      const onClose = new Promise(resolve => window.addEventListener('message', resolve));
      onClose.then((e) => {
        this.$log.debug(`oauthPopUpLogin: onClose: ${e.detail}`)
        if (e.data?.detail?.error) {
          this.onLoginFail(e.data?.detail?.error)
        } else {
          firebase.auth().signInWithCustomToken(e.data.detail.token)
              .then((result) => this.onLoginSuccessful(result))
              .catch(reason => this.onLoginFail(reason))
        }
      }).catch(() => {})
      const timer = setInterval((function (self) {
        return () => {
          if (!win || win.closed) {
            self.$log.debug(`win closed: ${win.closed}`)
            clearInterval(timer);
            self.loading = false;
          }
        }
      })(this), 1000);
    },
    _popupCenter({url, title, w, h}) {
      const left = (screen.width / 2) - (w / 2);
      const top = (screen.height / 2) - (h / 2);
      const newWindow = window.open(url, title,
          `
      scrollbars=yes,
      width=${w},
      height=${h},
      top=${top},
      left=${left}
      `
      )

      if (window.focus)
        newWindow.focus();
      return newWindow;
    },
    yandexLogin() {
      this._oauthPopUpLogin('/oauth/yandex_oauth/redirect', 'Yandex Auth', 600, 700);
    },

    vkLogin() {
      this._oauthPopUpLogin('/oauth/vk_oauth/redirect', 'VKontakte Auth', 600, 700);
    },

    okLogin() {
      this._oauthPopUpLogin('/oauth/ok_oauth/redirect', 'Odnoklassniki Auth', 600, 700);
    },

    onLoginBegin() {
      this.loading = true
    },
    onLoginEnd() {
      this.loading = false
    },
    onLoginFail(error) {
      this.$log.error(error)
      console.error(error)
    },
    // eslint-disable-next-line no-unused-vars
    onLoginSuccessful(result) {
      if (this.$route.query && this.$route.query.next) {
        this.$router.push({
          name: this.$route.query.next,
        }).catch(() => {
        })
      } else if (this.$route.query && this.$route.query.b) {
        this.$router.go(-1);
      } else {
        this.$router.push({
            name: "Home",
        }).catch(() => {});
      }
    },
  }
}
</script>
<style scoped>
  @media(max-height: 650px) and (min-width: 1024px){
    .centered-container {
      height: 150vh;
    }
  }
  @media(max-height: 650px){
    .centered-container {
      height: 135vh;
    }
  }
  @media (max-width:365px) {
    .title span {
      font-size: 24px;
    }
    .login-img {
      top: 18px;
      height: 30px;
      width: 30px;
    }
    .buttonLogin span {
      font-size: 20px;
    }
    .actions button {
      width: 95%;
      height: 65px;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .actions button.okLogin {
      background: #F7931E;
      margin-bottom: 32px;
    }
  }
</style>
